
import { reactive, ref, computed } from "vue";
import { alarmService } from "@/service";
import { useStore } from "@/store";

export default {
  setup() {
    const active = ref(0);
    const store = useStore();
    const levelColor = {
      "3": "#0065FF",
      "2": "#FF9A00",
      "1": "#FF5B34"
    };
    const statusColor = {
      "1": "#FF9A00",
      "2": "#0065FF",
      "3": "#FF4600",
      "4": "#959FAC",
      "5": "#959FAC"
    };
    const userInfo = computed(() => store.state.userInfo);

    const storeInfo = reactive<any>({
      inProgress: [],
      async getInProgressList() {
        try {
          const { data } = await alarmService.alarmLists({
            copy_user_id: userInfo?.value?.id,
            page: 1,
            pagesize: 9999999,
            alarm_states: "1,2,3",
            order: "1"
          });
          storeInfo.inProgress = data.data;
        } catch (error) {}
      },
      doneList: [],
      async getDoneList() {
        try {
          const { data } = await alarmService.alarmLists({
            copy_user_id: userInfo?.value?.id,
            page: 1,
            pagesize: 9999999,
            alarm_states: "4,5",
            order: "1"
          });
          storeInfo.doneList = data.data;
        } catch (error) {}
      }
    });

    storeInfo.getInProgressList();
    storeInfo.getDoneList();

    return { active, storeInfo, levelColor, statusColor };
  }
};
